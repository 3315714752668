import { Link } from "react-router-dom";

export default function LatestNewsOne({ bgColor, light }) {
  return (
    <>
      <section id="blog" className={`our-blog-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-heading mb-5">
                <h2 className={`${light ? "text-black" : "text-white"}`}>
                  Tin mới cập nhật
                </h2>
                <p className="lead">
                  Các tính năng mới sẽ luôn được cập nhật, vui lòng theo dõi để trải nghiệm ứng dụng tốt nhất, hướng tới mục tiêu kinh doanh của bạn.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  Tính năng
                </span>
                <img
                  src="assets/img/blog/1.jpg"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">1 thg1, 2024</li>
                      <li className="list-inline-item">
                        <span>45</span> Nhận xét
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Chia sẻ
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="https://docs.google.com/document/d/1jSd6Ng0J1pF6Fy7_ir52NVaJCH1euOK9MQXIjeTSkNY">
                      Làm thế nào để đặt đơn bán lẻ?
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    Ứng dụng cho phép tạo đặt đơn lẻ/bán buôn hàng hóa và các ngành dịch vụ. Để đặt đơn bán lẻ/bán buôn hàng hoa, bạn cần thực...
                  </p>
                  <Link to="https://docs.google.com/document/d/1jSd6Ng0J1pF6Fy7_ir52NVaJCH1euOK9MQXIjeTSkNY" className="detail-link">
                    Đọc tiếp <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  Tính năng
                </span>
                <img
                  src="assets/img/blog/2.jpg"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">2 thg1, 2024</li>
                      <li className="list-inline-item">
                        <span>30</span> Nhận xét
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Chia sẻ
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="https://docs.google.com/document/d/1BiaAuzWQEB7zoU61Q4zNAaCJoS5A6OapDtFBW64ZZ0M">Cách tạo sản phẩm hàng hóa</Link>
                  </h3>
                  <p className="card-text text-dark">
                    Sản phẩm hàng hóa là yêu cầu bắt buộc trước khi bạn tạo đơn hàng. Để tạo được sản phẩm hàng hóa, bạn cần tạo trước...
                  </p>
                  <Link to="https://docs.google.com/document/d/1BiaAuzWQEB7zoU61Q4zNAaCJoS5A6OapDtFBW64ZZ0M" className="detail-link">
                    Đọc thêm <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  Tính năng
                </span>
                <img
                  src="assets/img/blog/3.jpg"
                  className="card-img-top"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">3 thg1, 2024</li>
                      <li className="list-inline-item">
                        <span>41</span> Nhận xét
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Chia sẻ
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="https://docs.google.com/document/d/1lEjmnZRKtdLPEX09jWaRUyBczCmleq1OqBWkObmRpz4">Làm thế nào để quản lý mọi khoản Thu - chi?</Link>
                  </h3>
                  <p className="card-text text-dark">
                  Khoản Thu - chi là bao gồm tất cả các giao dịch tiền ra - vào trong hệ thống của bạn,...
                  </p>
                  <Link to="https://docs.google.com/document/d/1lEjmnZRKtdLPEX09jWaRUyBczCmleq1OqBWkObmRpz4" className="detail-link">
                    Đọc thêm <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
