import React from "react";

export default function Accordion({ Title }) {
  return (
    <>
      <section className={`container accordions pt-100 pb-60`}>
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading mb-5 text-center my-5 mt-lg-0">
              <h2>Các câu hỏi thường gặp</h2>
              <p className="lead">
                Bạn nên đọc các hướng dẫn trước khi bắt đầu sử dụng để tránh sai sót trong quá trình lưu trữ dữ liệu kinh doanh của bạn.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    Ứng dụng có miễn phí không?
                  </h6>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Miễn phí trải nghiệm 100% tất cả các tính năng trong ứng dụng và không giới hạn thời gian trải nghiệm.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-bag mr-3 color-secondary"></span>
                    Có thể dùng cho các ngành kinh doanh nào?
                  </h6>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Ứng dụng đáp ứng hầu hết các nhóm ngành kinh doanh hiện nay như: bán lẻ hàng hóa, dịch vụ đặt phòng / chỗ, dịch vụ tư vấn / sửa chữa, dịch vụ cho thuê, dịch vụ cho vay...
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span> Tôi có thể quản lý mọi khoản thu chi không?
                  </h6>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Bạn có thể quản lý mọi giao dịch thu chi với nhiều nguồn tiền đa dạng với chức năng Thu - chi trong ứng dụng.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-printer mr-3 color-secondary"></span>
                    Ứng dụng hỗ trợ in hóa đơn không?
                  </h6>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Ứng dụng hỗ trợ kết nối với máy in qua mạng LAN hoặc Bluetooth, có thể in bill cho khách hàng.
                    </p>
                    <p>
                      Ứng dụng sẽ hỗ trợ xuất hóa đơn VAT với một số nhà cung cấp hóa đơn điện tử lớn như Viettel, VNPT, FPT, BKAV trong thời gian tới.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-lock mr-3 color-secondary"></span> Dữ liệu của tôi được bảo mật thế nào?
                  </h6>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Dữ liệu được mã hóa với HTTPS và được bảo đảm an toàn tại hệ thống máy chủ của Amazon. Hệ thống đáp ứng các tiêu chuẩn khắt khe trong truyền dữ liệu của Apple Store và Google Play
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span> Có thể sử dụng cho doanh nghiệp không?
                  </h6>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      KiDoSo hoàn toàn đáp ứng được nhu cầu sử dụng của các doanh nghiệp vừa và nhỏ. KiDoSo cung cấp dữ liệu báo cáo chính xác cho các phương pháp tính thuế doanh nghiệp như khấu trừ...với giá vốn, giá bán, tồn kho...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
