import React from "react";

import Layout from "../components/layout/Layout";

export default function TermPage() {
  return (
    <Layout>
      <>
        <section className="ptb-40">
          <div className="container">
            <h3>Điều khoản sử dụng</h3>
            <div className="mt-3">
              <div>Điều khoản sử dụng này áp dụng cho việc sử dụng ứng dụng KiDoSo trên hệ điều hành Android và iOS.</div>
              <div>Bất cứ sự vi phạm nào của người dùng KiDoSo đối với các điều khoản và điều kiện này đều có thể dẫn đến việc bị đình chỉ hoặc kết thúc tài khoản của người dùng KiDoSo.</div>
            </div>
            
            <div className="mt-2">
              <strong className="">1. Điều kiện chung</strong>
              <div>Việc người dùng KiDoSo lựa chọn sử dụng ứng dụng KiDoSo đồng nghĩa với việc người dùng KiDoSo chấp thuận tuân thủ các điều khoản, chính sách, thỏa thuận sử dụng dịch vụ đã được công bố trong Điều khoản sử dụng này.</div>
              <div>Người dùng KiDoSo cũng phải tuân thủ các điều khoản của Apple App Store và Google Play về việc sử dụng dịch vụ.</div>
            </div>
            
            <div className="mt-2">
              <strong>2. Sử dụng hợp pháp</strong>
              <div>Chúng tôi cung cấp ứng dụng KiDoSo với các tính năng hỗ trợ quản lý bán hàng cho người dùng KiDoSo, vì vậy chúng tôi không can thiệp và không chịu trách nhiệm đối với bất kì nội dung hoạt động bán hàng của người dùng KiDoSo, bao gồm nhưng không giới hạn đối tác của người dùng KiDoSo, hàng hóa, chất lượng hàng hóa mà người dùng KiDoSo đang kinh doanh; cũng như không chịu trách nhiệm đối với các khiếu kiện nào từ người dùng KiDoSo hoặc bên thứ ba phản ánh về nội dung hoạt động bán hàng của người dùng KiDoSo trên ứng dụng.</div>
              <div>Người dùng KiDoSo phải nhận thức và phải có trách nhiệm sử dụng dịch vụ của KiDoSo vào công việc kinh doanh hàng hóa, dịch vụ trong khuôn khổ cho phép của pháp luật hiện hành và thuần phong mỹ tục của Việt Nam. Người dùng KiDoSo không được sử dụng dịch vụ của KiDoSo để thực hiện các hành động có thể gây ảnh hưởng đến quyền và lợi ích hợp pháp của của KiDoSo, cộng đồng hay quyền lợi của bất kì cơ quan, tổ chức, cá nhân nào, bao gồm nhưng không giói hạn việc tuyên truyền nội dung đồi trụy, chống phá nhà nước, kinh doanh hàng hóa thuộc danh mục hàng hóa cấm kinh doanh.</div>
              <div>Chúng tôi có toàn quyền tạm ngưng cung cấp hoặc ngăn chặn quyền tiếp tục truy cập ứng dụng của người dùng KiDoSo vào ứng dụng khi có căn cứ hoặc có dấu hiệu nghi ngờ người dùng KiDoSo có dấu hiệu vi phạm pháp luật hoặc gây ảnh hưởng đến sự an toàn của KiDoSo, quyền, lợi ích hợp pháp của cộng đồng, cơ quan, tổ chức, cá nhân khác.</div>
            </div>
            
            <div className="mt-2">
              <strong>3. Quyền sở hữu trí tuệ</strong>
              <div>Ứng dụng KiDoSo thuộc bản quyền của Công ty TNHH Giải Pháp Kinh Doanh Số. Do đó, tất cả nội dung trong ứng dụng KiDoSo và tại website www.kidoso.vn đều thuộc sở hữu của KiDoSo. Chúng tôi cho phép người dùng KiDoSo xem, tải về và in những nội dung sau:</div>
              <div>* Nội dung đã được KiDoSo công khai trên website, ứng dụng KiDoSo.</div>
              <div>* Nội dung các thông tin thuộc sở hữu của chính người dùng KiDoSo.</div>
              <div>Khi sử dụng các thông tin tại website / ứng dụng KiDoSo, người dùng KiDoSo cần trích dẫn nguồn từ website theo đúng quy định, chỉ sử dụng vào các mục đích phi lợi nhuận.</div>
              <div>Ngoại trừ việc cho phép như trên, người dùng KiDoSo không được phép sao chép, điều chỉnh hoặc sử dụng lại nội dung website / ứng dụng KiDoSo mà không có sự cho phép trước đó bằng văn bản của chúng tôi.</div>
            </div>

            <div className="mt-2">
              <strong>4. Giới hạn trách nhiệm</strong>
              <div>Chúng tôi không chịu trách nhiệm và không bảo đảm về tính chính xác về những thông tin của người dùng KiDoSo trên website / ứng dụng của người dùng KiDoSo. Đồng thời, chúng tôi không chịu trách nhiệm pháp lý và bồi thường cho người dùng KiDoSo và bên thứ ba đối với các thiệt hại trực tiếp, gián tiếp, vô ý, vô hình, các thiệt hại về lợi nhuận, doanh thu, uy tín phát sinh từ việc sử dụng sản phẩm, dịch vụ của KiDoSo.</div>
              <div>Chúng tôi không chịu trách nhiệm hoặc trách nhiệm liên đới nào đối với hậu quả của việc truy cập trái phép đến máy chủ, website / ứng dụng KiDoSo; trang thiết bị và dữ liệu của người dùng KiDoSo hoặc dữ liệu cá nhân của người dùng KiDoSo vì tai nạn, phương tiện bất hợp pháp, thiết bị của bên thứ ba và các nguyên nhân khác nằm ngoài sự kiểm soát của chúng tôi.</div>
            </div>

            <div className="mt-2">
              <strong>5. Đảm bảo cung cấp dịch vụ</strong>
              <div>Ứng dụng KiDoSo được cung cấp trên cơ sở “có thể thực hiện được”. Chúng tôi không bảo đảm rằng ứng dụng của chúng tôi sẽ luôn luôn sẵn sàng, luôn có thể sử dụng, không bao giờ bị gián đoạn, đúng thời gian, không có lỗi bởi các sự cố, bao gồm nhưng không giới hạn như hacker hoặc mất mạng Internet trên diện rộng. Tuy nhiên, chúng tôi cam kết cố gắng trong mọi điều kiện và làm hết sức khả năng có thể để đảm bảo rằng ứng dụng KiDoSo luôn được sẵn sàng cho việc sử dụng.</div>
              <div>Chúng tôi cam kết nỗ lực khắc phục sự gián đoạn và đưa ra sự điều chỉnh, sửa chữa và hỗ trợ trong khả năng có thể để phục hồi ứng dụng nhanh chóng trong trường hợp dịch vụ bị gián đoạn hoặc bị lỗi.</div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}