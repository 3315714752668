import React from "react";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logo-white-1x.png"
                    alt="footer logo"
                    width="120"
                    className="img-fluid"
                  />
                  <div>Công ty TNHH Giải Pháp Kinh Doanh Số</div>
                  <div className="mt-3">
                    Doanh nghiệp chuyên cung cấp các giải pháp kinh doanh số 4.0 cho cá nhân, hộ kinh doanh, doanh nghiệp vừa và nhỏ.
                  </div>

                  <div className="social-list-wrap mt-4">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/kidoso.vn" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li> */}
                      {/* <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li> */}
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Tài nguyên</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="https://www.facebook.com/kidoso.vn">Hỗ trợ</a>
                        </li>
                        <li className="mb-2">
                          <a href="https://www.facebook.com/kidoso.vn">Hỗ trợ trực tuyến</a>
                        </li>
                        <li className="mb-2">
                          <a href="https://www.facebook.com/kidoso.vn">Tài liệu sử dụng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Doanh nghiệp</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="https://www.facebook.com/kidoso.vn">Về chúng tôi</a>
                        </li>
                        <li className="mb-2">
                          <a href="https://www.facebook.com/kidoso.vn">Cơ hội nghề nghiệp</a>
                        </li>
                        {/* <li className="mb-2">
                          <a href="#/">Customers</a>
                        </li> */}
                        <li className="mb-2">
                          <a href="https://www.facebook.com/groups/kidoso.vn">Cộng đồng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Thông tin</h5>
                      <ul className="list-unstyled support-list">
                        {/* <li className="mb-2">
                          <a href="#about">Quy chế hoạt động</a>
                        </li> */}
                        <li className="mb-2">
                          <a href="/end-user-license-agreement">Điều khoản sử dụng</a>
                        </li>
                        <li className="mb-2">
                          <a href="/privacy-policy">Chính sách bảo mật</a>
                        </li>
                      </ul>
                      {/* <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          Tp. Hồ Chí Minh
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel:+61283766284"> 0813 344344</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:hotro@kidoso.vn">
                            {" "}
                            ceo@kidoso.vn
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © Bàn quyền thuộc về KiDoSo
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="text-right small-text">
                    <div className="text-white">306/5 Phạm Văn Bạch, P.15, Q.Tân Bình, Tp.Hồ Chí Minh</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
