import React from "react";

import Layout from "../components/layout/Layout";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";

export default function ContactUs() {
  return (
    <Layout>
      <Navbar classOption="custom-header" />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}