import React from "react";

export default function CounterTwo() {
  return (
    <>
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <ul className="list-inline counter-wrap">
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>2.000+</span>
                  <h6>Khách hàng</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>98%</span>
                  <h6>Hài lòng</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>4.8</span>
                  <h6>Điểm đánh giá</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

{/* <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center card single-promo-card single-promo-hover">
                <h3 className="display-1 font-weight-bolder">10K+</h3>
                <p className="mb-0">
                  10K+ khách hàng sử dụng thường xuyên
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">96% </h3>
                <p className="mb-0">
                  Hài lòng với giải pháp của chúng tôi.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">4.9</h3>
                <p className="mb-0">
                  Điểm đánh giá ứng dụng tốt nhất.
                </p>
              </div>
            </div>
          </div> */}
