import React from "react";

import Layout from "../components/layout/Layout";
import Contact from "../components/prices/Price";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Price from "../components/prices/Price";

export default function PriceTable() {
  return (
    <Layout>
      <Navbar classOption="custom-header" />
      <Price bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}