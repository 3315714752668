import React from "react";

export default function GuideStatic() {
  return (
    <>
      <section className={`gray-light-bg ptb-60`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h4>Hướng dẫn sử dụng</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <ul className="list-unstyled support-list">
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1YBo4Zl4A72Y3IQ5SGJjZfEm2levw8HfOk8W1ERGFNmk">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Màn hình chức năng chính</span>
                  </a>
                </li>
                
                {/*  <li className="mb-3">
                  <a href="https://www.facebook.com/kidoso.vn">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách đặt đơn dịch vụ cho thuê?</span>
                  </a>
                </li> */}
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1_z4_UvWTIZrkaSl_3AjEeOaOQA4dJUjA6EMD9OkM_9k">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập chỗ /phòng</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1DE3uYVzL_rLt63yEzVaBP5gYlUJUHIKxfyV9NaoasAE">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập vị trí chuyên môn</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1c6uEXaR7jFKyrtcIOIfNuXZTea2UUWFJ5Y61d0qqyAM">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thêm và quản lý vai trò của nhân viên</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1HOTorrnA3r3JtFAMyp6n3BxR6WLjMb-IUXMjR144DHE">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập nguồn tiền (phương thức thanh toán)</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1N91r2FQbqTOpge-ycmVrsFbImi6k712XD3lV9D7YQuE">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập máy in & Chỉnh thông tin biên nhận (Bill)</span>
                  </a>
                </li>
                
                {/* <li className="mb-3">
                  <a href="https://www.facebook.com/kidoso.vn">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Điều chỉnh giao diện cho nhóm ngành dịch vụ</span>
                  </a>
                </li> */}
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1k6clQgPx8JfnSJAGwO-HzD1g-VkCKEl2jB5ndcoCZ28">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách dùng khoản Thu tạm ứng trong đơn bán hàng?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1ePMY6FZO8G_O8SN9hpEqjipqC9zh2HVaaORT1_WmY-M">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách dùng khoản Chi đã tạm ứng trong đơn mua hàng?</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="list-unstyled support-list">
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1BiaAuzWQEB7zoU61Q4zNAaCJoS5A6OapDtFBW64ZZ0M">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách tạo sản phẩm hàng hóa</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1WAfNuCrDUmxI-J3wIJ0ec69bxdRXMP97U96c4CirD8I">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách tạo sản phẩm dịch vụ</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1eDNGWbssRFahLonK0ehcaZFkxChrrF9TdyPGBqc8H78">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập giá sỉ cho hàng hóa</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1lCxfSOVRlu4L78srhNDbNGafvUbz08WcKKj_90m0wvQ">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Thiết lập đơn vị quy đổi cho hàng hóa</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1jSd6Ng0J1pF6Fy7_ir52NVaJCH1euOK9MQXIjeTSkNY">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Làm sao để tạo đơn bán hàng hóa?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1mb3JzEwRbujJUCSk8Cjmie8FIbYnjWNHLY_NEDb65yE">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách đặt đơn dịch vụ nhóm ngành đặt chỗ /phòng?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1OOO-W1uEV3PtWDeTH7QVinuQ9YM4MfLw6qM7JI-K4qs">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách đặt đơn dịch vụ nhóm ngành đặt lịch hẹn?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1R7-d2W30htZwRoxPGojO2ifaA-lLOChnm7AoZO78h1M">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Làm sao để nhập kho hàng hóa?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1D_HtHO-PNgfDl5QL-DMMODo6tWJVu2463vXW9UptV2o">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Cách điều chỉnh giảm số lượng hàng hóa trong kho?</span>
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://docs.google.com/document/d/1lEjmnZRKtdLPEX09jWaRUyBczCmleq1OqBWkObmRpz4">
                    <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Làm thế nào để quản lý mọi khoản Thu - chi?</span>
                  </a>
                </li>
                {/* <li className="mb-3">
                  <a href="https://www.facebook.com/kidoso.vn">
                  <span className="ti-hand-point-right mr-3 color-secondary"></span>
                    <span className="text-dark">Làm sao để thêm mới cửa hàng?</span>
                  </a>
                </li> */}
                
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}