import React from "react";

export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Liên hệ</h2>
                <p>
                  Nếu bạn có bất cứ thắc mắc, góp ý hay yêu cầu nào, hãy gửi cho chúng tôi. Xin chân thành cảm ơn đã sử dụng dịch vụ của KiDoSo
                </p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Trụ sở chính</strong>
                </h6>
                <p>306/5 Phạm Văn Bạch, P.15, Q.Tân Bình, Tp. Hồ Chí Minh</p>
                <ul>
                  <li>
                    <span>
                      Điện thoại:&nbsp;
                      <a href="tel:0813344344">
                        0813 344344
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      Email:&nbsp;
                      <a href="mailto:ceo@kidoso.vn">
                        ceo@kidoso.vn
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                <h5>Để lại góp ý</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Nhập vào tên bạn"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Nhập vào email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="SĐT của bạn"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder="Địa chỉ"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Nội dung tin nhắn"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Gửi ngay
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
