import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  Quản lý mọi thứ chỉ với<br />
                  chiếc điện thoại
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/icon-monitor.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Hệ thống báo cáo đơn hàng, doanh thu, dòng tiền, tồn kho, chi phí trực quan.<br/>
                      Theo dõi hiệu quả hoạt động của nhân viên/cộng tác viên dễ dàng
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/icon-database.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Hợp nhất các nguồn dữ liệu, loại bỏ excel và sổ giấy.<br/>
                      Lưu trữ dữ liệu đơn hàng, khách hàng, thu - chi, ghi nợ, hóa đơn, sản phẩm...online, an toàn và truy cập mọi nơi.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/icon-campaign.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Tiếp cận nhiều khách hàng tiềm năng hơn, thúc đẩy các chiến dịch kinh doanh của bạn với khuyến mại, loyalty và tính năng khám phá.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/icon-cross-platform.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Ứng dụng có trên iOS, Android, (sắp có mặt trên Windows & MacOS). Hỗ trợ hoạt động đa nền tảng, đáp ứng đầy đủ yêu cầu của bạn.
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/image-08.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
