import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Bắt đầu sử dụng app để quản lý kinh doanh của bạn
                </h2>
                <p className="lead">
                  Quản lý chuyên nghiệp, chuyển đổi số 4.0 cho kinh doanh của bạn. 
                  Tất cả được tích hợp trên một ứng dụng, tải và trải nghiệm ngay. 
                </p>

                <div className="download-btn">
                  <Link 
                    to="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=vn.viorg.kidoso" 
                    className="btn google-play-btn mr-3"
                  >
                    <span className="ti-android"></span> Google Play
                  </Link>
                  <Link to="/" className="btn app-store-btn">
                    <span className="ti-apple"></span> App Store
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end pb-100">
                <img
                  src="assets/img/image-12.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
