import React from "react";

import Layout from "../components/layout/Layout";

export default function PrivacyPolicy () {
  return (
    <Layout>
      <>
        <section className="ptb-40">
          <div className="container">
            <h3>Chính sách bảo mật</h3>
            <div className="mt-3">
              <div>Chính sách Bảo mật này mô tả cách chúng tôi thu thập, xử lý và công khai dữ liệu cá nhân của người dùng nếu họ chọn sử dụng ứng dụng KiDoSo. Bằng cách sử dụng ứng dụng KiDoSo, bạn đồng ý với việc thu thập và xử lý dữ liệu được mô tả trong Chính sách này.</div>
              <div>Các thuật ngữ được dùng trong Chính sách Bảo mật này có ý nghĩa tương tự như trong Điều khoản dịch vụ, trừ khi được định nghĩa khác trong Chính sách này.</div>
            </div>

            <div className="mt-2">
              <strong>Về việc thu thập và sử dụng dữ liệu</strong>
            </div>

            <div className="mt-2">Để đem đến trải nghiệm tốt hơn khi dùng ứng dụng, chúng tôi có thể yêu cầu bạn cung cấp các thông tin nhận dạng cá nhân, bao gồm nhưng không giới hạn địa chỉ email, số điện thoại, tên cửa hàng, tên doanh nghiệp. Chúng tôi không bán, trao đổi hoặc cho thuê thông tin nhận dạng cá nhân của người dùng cho người khác.</div>
            <div>Tuy nhiên, chúng tôi có sử dụng dịch vụ của các bên thứ ba để phát triển ứng dụng và có thể chia sẻ thông tin của bạn với các bên thứ ba với mục đích vận hành và phát triển ứng dụng.</div>
            <div>Ngoài ra, chúng tôi có thể liên hệ với bạn bằng những thông tin mà bạn cung cấp để thu thập những đánh giá, ý kiến và quan điểm của bạn về ứng dụng.</div>
            <div>Đường link tới các bên thứ ba mà ứng dụng sử dụng:</div>

            <div className="mt-2">
              <ul>
                <li className="mb-1">
                  <a href="https://policies.google.com/privacy">Google Play Services</a>
                </li>
                <li className="mb-1">
                  <a href="https://firebase.google.com/policies/analytics">Google Analytics for Firebase</a>
                </li>
                <li className="mb-1">
                  <a href="https://firebase.google.com/support/privacy">Firebase Crashlytics</a>
                </li>
                <li className="mb-1">
                  <a href="https://www.facebook.com/about/privacy/update/printable">Facebook</a>
                </li>
              </ul>
            </div>

            <div className="mt-3">
              <strong>Danh bạ của bạn</strong>
              <div className="mt-2">Bạn có thể sử dụng tính năng đăng tải liên hệ (contact) từ danh bạ và cung cấp cho chúng tôi số điện thoại trong danh bạ của bạn. Tính năng này giúp bạn phân biệt phân biệt các đối tác khác nhau, cũng như giúp bạn phân tích tình hình kinh doanh của bạn với mỗi đối tác. Khi bạn đăng tải liên hệ cho chúng tôi, tên liên hệ và số điện thoại sẽ được lưu giữ trong hệ thống dữ liệu của chúng tôi.</div>
              <div className="mt-2">Dữ liệu danh bạ của bạn được đảm bảo bí mật, không bị chia sẻ hoặc bán cho bất kỳ bên thứ 3 nào, chúng tôi chỉ cung cấp thông tin nếu chính quyền của bạn yêu cầu theo luật pháp.</div>
              <div className="mt-2">Chúng tôi không sử dụng danh bạ của bạn vào bất kỳ mục đích tiếp thị hay thương mại nào của chúng tôi, chỉ sử dụng thông tin này để phát triển dịch vụ của chúng tôi, và giúp phân tích thông số sử dụng của ứng dụng. Bạn có thể xoá các liên hệ đã đăng tải bất cứ lúc nào, bằng cách xoá liên hệ trong ứng dụng, hoặc yêu cầu xoá trực tiếp với chúng tôi.</div>
            </div>

            <div className="mt-3">
              <strong>Nhật ký dữ liệu (Log Data)</strong>
              <div className="mt-2">Khi ứng dụng bị lỗi, chúng tôi sẽ thu thập dữ liệu và thông tin (thông qua dịch vụ của một bên thứ ba) trên thiết bị di động của bạn thông qua Nhật ký dữ liệu (Log Data). Nhật ký dữ liệu có thể bao gồm thông tin như địa chỉ IP của thiết bị, tên thiết bị, hệ điều hành, cấu hình thiết bị khi sử dụng ứng dụng, thời gian truy cập vào ứng dụng và các dữ liệu thống kê khác.</div>
            </div>

            <div className="mt-3">
              <strong>Về việc sử dụng cookies</strong>
              <div className="mt-2">Cookie là những thư mục dữ liệu được lưu trữ tạm thời hoặc lâu dài trong ổ cứng máy tính của Khách hàng. Ứng dụng KiDoSo không dùng cookies trực tiếp; tuy nhiên, ứng dụng có thể dùng mã lệnh (code) và các thư viện mã lệnh của bên thứ ba mà có dùng cookies để thu thập dữ liệu và cải thiện dịch vụ của họ. Bạn có thể chấp nhận hoặc từ chối sử dụng những cookies này. Nếu bạn từ chối sử dụng cookies, bạn có thể không sử dụng được một số phần nhất định của ứng dụng này.</div>
            </div>

            <div className="mt-3">
              <strong>Về việc sử dụng các nhà cung cấp dịch vụ</strong>
              <div className="mt-2">Chúng tôi có thể sử dụng dịch vụ của các bên thứ ba vì những lý do sau:</div>
              <div>* Để phát triển ứng dụng của chúng tôi;</div>
              <div>* Để cung cấp dịch vụ thay mặt chúng tôi;</div>
              <div>* Để thực hiện các dịch vụ liên quan, hoặc</div>
              <div>* Để hỗ trợ chúng tôi phân tích thông tin thu thập bởi ứng dụng</div>
              <div>Các bên thứ ba này có quyền truy cập vào thông tin cá nhân của bạn với mục đích cung cấp dịch vụ cho chúng tôi. Tuy nhiên, các bên thứ ba có nghĩa vụ không tiết lộ hoặc sử dụng thông tin của bạn cho bất kỳ mục đích nào khác.</div>
            </div>

            <div className="mt-3">
              <strong>Bảo mật</strong>
              <div className="mt-2">Chúng tôi đã triển khai các tiêu chuẩn chung được công nhận về bảo mật công nghệ và vận hành để bảo vệ các dữ liệu cá nhân khỏi bị mất, lạm dụng, thay đổi hoặc tiêu hủy. Chỉ những người có thẩm quyền trong đội ngũ của chúng tôi và các nhà cung cấp dịch vụ bên thứ ba của chúng tôi mới được cấp quyền truy cập vào dữ liệu cá nhân của bạn.</div>
              <div>Các nhân viên cùng các nhà cung cấp dịch vụ bên thứ ba này được yêu cầu bảo mật thông tin này. Dù đã áp dụng các biện pháp phòng ngừa, nhưng chúng tôi không thể bảo đảm 100% những người không có thẩm quyền không thể truy cập vào dữ liệu cá nhân của bạn.</div>
              <div>Trong trường hợp rò rỉ bảo mật thông tin, KiDoSo sẽ có trách nhiệm phối hợp với các bên cung cấp dịch vụ thứ ba này để tìm ra nguyên nhân và phối hợp khắc phục thiệt hại cho bạn.</div>
            </div>

            <div className="mt-3">
              <strong>Liên kết đến các trang web khác</strong>
              <div className="mt-2">Ứng dụng này có thể chứa các liên kết đến các trang web khác. Nếu bạn nhấp vào liên kết của bên thứ ba, bạn sẽ được dẫn đến trang web đó. Lưu ý rằng các trang web bên ngoài này không do chúng tôi điều hành. Do đó, chúng tôi khuyến khích bạn xem lại Chính sách Bảo mật của các trang web này.</div>
              <div>Chúng tôi không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc cách vận hành của bất kỳ trang web hoặc dịch vụ của bên thứ ba nào.</div>
            </div>

            <div className="mt-3">
              <strong>Quyền riêng tư của trẻ em</strong>
              <div className="mt-2">Ứng dụng này không liên quan đến bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ trẻ em dưới 13 tuổi. Trong trường hợp chúng tôi phát hiện rằng trẻ em dưới 13 tuổi đã cung cấp thông tin cá nhân cho chúng tôi, chúng tôi sẽ ngay lập tức xóa thông tin này khỏi máy chủ của chúng tôi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con bạn đã cung cấp thông tin cá nhân cho chúng tôi, vui lòng liên hệ với chúng tôi để chúng tôi có thể thực hiện các hành động cần thiết.</div>
            </div>

            <div className="mt-3">
              <strong>Xoá tài khoản KiDoSo của bạn</strong>
              <div className="mt-2">Bạn có thể chủ động xóa tài khoản của bạn bất cứ lúc nào bằng cách truy cập: Nhiều hơn &#8594; Tài khoản &#8594; Chọn nút xóa </div>
              <div>Ngoài ra, bạn có thể xoá tài khoản KiDoSo của mình bằng cách liên hệ địa chỉ email ceo@kidoso.vn hoặc nhấn vào <a href="/contact-us">Liên hệ</a> để gửi yêu cầu. Khi xoá tài khoản, tất cả thông tin và dữ liệu của bạn trong quá trình sử dụng KiDoSo sẽ được xoá khỏi hệ thống dữ liệu và máy chủ của chúng tôi, loại trừ nhật ký dữ liệu (Log Data).</div>
            </div>

            <div className="mt-3">
              <strong>Các thay đổi đối với Chính sách Bảo mật này</strong>
              <div className="mt-2">Chúng tôi có thể cập nhật Chính sách Bảo mật của chúng tôi theo thời gian. Do đó, bạn nên xem lại Chính sách này định kỳ để cập nhật các thay đổi mới.</div>
            </div>

            <div className="mt-3">
              <strong className="font-italic">Chính sách Bảo mật này có hiệu lực từ ngày 01/01/2024.</strong>
            </div>

            <div className="mt-3">
              <strong>Liên hệ chúng tôi</strong>
              <div className="mt-2">Nếu có thắc mắc về chính sách này, bạn có thể liên hệ với chúng tôi theo địa chỉ nêu bên dưới.</div>
              <div>Bạn có thể liên hệ với chúng tôi qua email: ceo@kidoso.vn hoặc gửi thư theo địa chỉ:</div>
              <div className="mt-3">
                <div>Công ty TNHH Giải Pháp Kinh Doanh Số</div>
                <div>Nơi nhận: Phòng pháp chế</div>
                <div>306/5 Phạm Văn Bạch, P.15, Q.Tân Bình, Tp.Hồ Chí Minh</div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}