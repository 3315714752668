import React from "react";

import Layout from "../components/layout/Layout";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Accordion from "../components/Faqs/Accordion";
import GuideStatic from "../components/Faqs/GuideStatic";

export default function Faq() {
  return (
    <Layout>
      <Navbar classOption="custom-header" />
      <Accordion Title />
      <GuideStatic />
      <Footer />
    </Layout>
  );
}
