import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/home/Home";
import ContactUs from "../pages/contact/ContactUs";
import Faq from "../pages/faqs/Faq";
import PriceTable from "../pages/price/PriceTable";
import TermPage from "../pages/term/Term";
import PrivacyPolicyPage from "../pages/policy/PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/prices" element={<PriceTable />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/end-user-license-agreement" element={<TermPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/fags" element={<Faq />} />
      </Routes>
    </>
  );
};

export default AppRoutes;