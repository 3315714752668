import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Tính năng nổi bật
                </span>
                <h2>Đầy đủ tính năng cho quản lý 4.0</h2>
                <p>
                  Luôn luôn tối ưu ứng dụng để trở nên dễ hiểu và sử dụng. Mục tiêu đưa đến sản phẩm tốt nhất đồng hành cùng công việc kinh doanh của bạn.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <img
                      src="assets/img/icons8-warehouse.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Quản lý tồn kho, hạn sử dụng</h5>
                  <p className="mb-0">
                    Quản lý xuất nhập kho, đơn mua, công nợ nhà cung cấp. <br/>
                    Theo dõi HSD, số lượng theo lô sản phẩm.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <img
                      src="assets/img/icons8-debt.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Quản lý thu - chi, dòng tiền</h5>
                  <p className="mb-0">
                    Ghi chép thu chi phát sinh, lưu trữ online, truy cập mọi nơi.<br/>
                    Báo cáo thu - chi trực quan, dễ hiểu.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <img
                      src="assets/img/icons8-booking.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Quản lý đơn hàng 4.0</h5>
                  <p className="mb-0">
                    Quản lý đặt đơn, thanh toán, hoàn hủy, đổi - trả dễ dàng.<br />
                    Nhắc đơn tự động, ưu việt cho đơn giao sau, khách checkin.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <img
                      src="assets/img/icons8-gift.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Quản lý khuyến mại</h5>
                  <p className="mb-0">
                    Tạo và quản lý khuyến mại, tích điểm thành viên.<br/>
                    Hỗ trợ thúc đẩy doanh số và giữ chân khách hàng.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <img
                      src="assets/img/icon-invoice.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Đủ cho báo cáo thuế</h5>
                  <p className="mb-0">
                    Dữ liệu chính xác trên từng đơn hàng. <br />
                    Xuất file excel giúp bạn tổng hợp báo cáo thuế.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <img
                      src="assets/img/note-book.png"
                      alt="icon "
                      className="img-fluid m-2"
                    />
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Sổ ghi nợ 4.0</h5>
                  <p className="mb-0">
                    Thay thế sổ nợ giấy, excel bằng ứng dụng nhắc và tính nợ tự động.<br />
                    Chi tiết từng khoản vay - cho vay, hỗ trợ tất toán một hoặc nhiều khoản.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
